<template>
  <div>
    <vs-table search stripe border description :sst="false" :data="table.data">
      <template slot="thead">
        <vs-th>No</vs-th>
        <vs-th>Name</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            {{ indextr + 1 }}
          </vs-td>
          <vs-td>
            {{ tr.name }}
          </vs-td>
          <vs-td>
            <vs-button
              @click="onClickBtnSync(tr)"
              color="primary"
              size="small"
              type="filled"
            >
              Sync
            </vs-button>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <!-- modal -->
    <vs-prompt
      title="Confirm"
      color="primary"
      accept-text="Sync Now"
      @accept="onClickBtnModalAccept"
      :active.sync="activeModalSync"
    >
      <div class="con-exemple-prompt">
        <div v-for="(form, idx) in forms" :key="idx">
          <div v-if="form.type == 'date'" class="my-2">
            <vs-input
              v-model="form.value"
              :label="form.label"
              type="date"
              class="w-full"
            />
          </div>
          <div v-else-if="form.type == 'time'" class="my-2">
            <vs-input
              v-model="form.value"
              :label="form.label"
              type="time"
              class="w-full"
            />
          </div>
          <div v-else-if="form.type == 'datetime'" class="my-2">
            <vs-input
              v-model="form.value"
              :label="form.label"
              type="datetime-local"
              class="w-full"
            />
          </div>
          <div v-else class="my-2">
            <vs-input
              v-model="form.value"
              :label="form.label"
              class="w-full"
              :placeholder="form.placeholder"
            />
          </div>
        </div>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import moment from "moment";

export default {
  components: {},
  props: {
    baseUrl: {
      type: String,
    },
  },
  data() {
    return {
      table: {
        data: [],
      },
      activeModalSync: false,
      forms: [],
      selectedRow: null,
    };
  },
  methods: {
    parseValue(type, value) {
      switch (type) {
        case "date":
          return moment(value).format("YYYY-MM-DD");
        case "datetime":
          return moment(value).format("YYYY-MM-DD HH:mm:ss");
        default:
          return value;
      }
    },
    onClickBtnModalAccept() {
      this.activeModalSync = false;
      // this.$vs.loading();
      let row = this.selectedRow;
      let url = row.url;

      if (row.params != null) {
        for (let i = 0; i < row.params.length; i++) {
          if (i == 0) {
            url += "?";
          } else {
            url += "&";
          }

          let value = this.parseValue(row.params[i].type, row.params[i].value);

          url += row.params[i].name + "=" + value;
        }
      }

      this.$http.get(url).then((resp) => {
        if (resp.code == 200) {
          this.$vs.notify({
            title: "Success",
            text: "Sync Success",
            color: "success",
            position: "top-right",
          });
          this.$vs.loading.close();
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Sync Error",
            color: "danger",
            position: "top-right",
          });
          this.$vs.loading.close();
        }
      });
    },
    cleanData() {
      this.forms = [];
      this.selectedRow = null;
    },
    onClickBtnSync(row) {
      this.selectedRow = row;
      this.activeModalSync = true;
      this.forms = row.params;
    },
    getData() {
      this.$vs.loading();
      this.$http.get(this.baseUrl).then((resp) => {
        if (resp.code == 200) {
          this.table.data = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
