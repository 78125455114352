<template>
  <vx-card title="Sync Master">
    <div class="vx-row">
      <div class="vx-col sm:w-1/1 w-full mb-base">
        <vs-tabs>
          <vs-tab label="History">
            <data-table :baseUrl="this.baseUrl"></data-table>
          </vs-tab>
          <vs-tab label="Pull">
            <data-table-pull :baseUrl="this.baseUrlPull" />
          </vs-tab>
        </vs-tabs>
      </div>
    </div>
  </vx-card>
</template>

<script>
import DataTable from "./Datatable";
import DataTablePull from "./DatatablePull";
export default {
  components: {
    DataTable,
    DataTablePull,
  },
  data() {
    return {
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      baseUrl: "/api/sfa/v1/sync-master",
      baseUrlPull: "/api/sfa/v1/sync-master/table-pull",
    };
  },
  methods: {},
  mounted() {},
  watch: {},
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
