<template>
  <div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Filter by Note Column</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          v-model="selectedFilterNotes"
          :options="optionFilterNotes"
          :multiple="true"
          :group-select="false"
          :max-height="100"
          :searchable="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option }}</span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="vx-row mb-6 w-1/2">
      <!-- submit button -->
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <vs-button color="primary" type="filled" @click="getData()"
          >Submit</vs-button
        >
      </div>
    </div>

    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <!-- <vs-th></vs-th> -->
        <vs-th>Ex ID</vs-th>
        <vs-th>Note</vs-th>
        <vs-th>Description</vs-th>
        <vs-th>Message</vs-th>
        <vs-th>Zh ID</vs-th>
        <vs-th>Status</vs-th>
        <vs-th>Created On</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            {{ tr.ex_id }}
          </vs-td>
          <vs-td>
            {{ tr.note }}
          </vs-td>
          <vs-td>
            {{ tr.description }}
          </vs-td>
          <vs-td>
            {{ tr.message }}
          </vs-td>
          <vs-td>
            {{ tr.zh_id }}
          </vs-td>
          <vs-td>
            {{ tr.status | formatStatus }}
          </vs-td>
          <vs-td>
            {{ tr.created_at | formatDate }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import moment from "moment";

export default {
  components: {},
  props: {
    baseUrl: {
      type: String,
    },
    detail: {
      type: Boolean,
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),

      // options
      selectedFilterNotes: [],
      optionFilterNotes: [],
    };
  },
  filters: {
    formatStatus(value) {
      if (value == 1) {
        return "Success";
      } else {
        return "Failed";
      }
    },
    formatDate(value) {
      return moment(value).format("DD MMM YYYY HH:mm:ss");
    },
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      if (searching.length >= 3) {
        this.table.search = searching;
        this.table.page = 1;
        this.getData();
      }
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            notes: this.selectedFilterNotes.join(","),
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    async getOptionFilterNotes() {
      const res = await this.$http.get(
        "/api/sfa/v1/sync-master/filter-data/note"
      );
      this.optionFilterNotes = res.data;
    },
  },
  watch: {},
  mounted() {
    this.getOptionFilterNotes();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
